<template>
    <div>
        <div style="padding: 0 10px">
            <van-search
                    v-model="value"
                    show-action
                    shape="round"
                    placeholder="请输入书名或者作者名"
                    @input="onSearch"
                    class="search_input"
            >
                <template #action>
                    <div @click="$router.go(-1)" class="quxiao">取消</div>
                </template>
            </van-search>
        </div>
        <div class="div">
            <template v-if="value.length == 0">
                <div class="search-center">
                    <div class="search-top">
                        <p class="search-title">搜索历史</p>
                        <p class="del_icon" @click="clearClick">
                            清空
                            <img src="../../assets/images/del_icon.png" alt="">
                        </p>
                    </div>
                    <div class="history">
                        <template v-for="item in searchList.search">
                            <p @click="searchClick(item)">{{item}}</p>
                        </template>
                        <!--                        <p>校花的修仙继承者-->
                        <!--                            <van-icon name="fire" color="#D53D3C" size="10"/>-->
                        <!--                        </p>-->
                    </div>
                    <div class="search-top">
                        <p class="search-title">热门搜索</p>
                    </div>
                    <div class="history">
                        <template v-for="item in searchList.hot_keywords">
                            <p @click="searchClick(item)">{{item}}</p>
                        </template>
                    </div>
                    <div class="search-top">
                        <p class="search-title">热门推荐</p>
                    </div>
                    <div>
                        <div class="record-list">
                            <template v-for="item in searchList.recommend">
                                <div class="record-left" @click="detailsClick(item)">
                                    <p class="points_relative">
                                        <img :src="item.coverpic" alt="" class="book_img">
                                        <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                                    </p>
                                    <div class="list-right">
                                        <div class="search-list">
                                            <p class="title"><span>{{item.title}}</span></p>
                                            <p class="title-right" v-if="item.average_score != 0"><span>{{item.average_score}}</span>评分
                                            </p>
                                        </div>
                                        <p class="subtitle">
                                            <img src="../../assets/images/author_icon.png" alt="" class="author_icon">
                                            <span>{{item.author}}</span>
                                        </p>
                                        <div class="search-list">
                                            <p class="time">{{item.desc}}</p>
                                            <p class="record-right">{{item.classify.name}}</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="search-top">
                    <p class="search-title">全部结果</p>
                </div>
                <div class="record-list">
                    <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
                        <van-list
                                v-model="loading"
                                :finished="finished"
                                :finished-text="finished_text"
                                @load="onLoad"
                        >
                            <template v-for="item in indexList">
                                <div class="record-left" @click="detailsClick(item)">
                                    <p class="points_relative">
                                        <img :src="item.coverpic" alt="" class="book_img">
                                        <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                                    </p>
                                    <div class="list-right">
                                        <div class="search-list">
                                            <p class="title"><span>{{item.title}}</span></p>
                                        </div>
                                        <p class="subtitle">
                                            <img src="../../assets/images/author_icon.png" alt="" class="author_icon">
                                            <span>{{item.author}}</span>
                                        </p>
                                        <div class="search-list">
                                            <p class="time time_acitve">{{item.desc}}</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="indexList.length == 0">
                                <div class="no">
                                    <No title="暂时没有该书籍哦！"></No>
                                </div>
                            </template>
                        </van-list>
                    </van-pull-refresh>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import No from '../../components/No'

    export default {
        components: {
            No,
        },
        name: "Search",
        data() {
            return {
                value: '',
                searchList: [],
                page: 1,
                page_size: 10,
                indexList: [],
                moreList_show: false,
                loading: false,
                finished: false,
                refreshing: false,
                finished_text: '',
            }
        },
        created() {
            this.base_infoClick()
        },
        methods: {
            onRefresh() {
                this.page = 1
                if (this.indexList.length >= this.count) {
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.moreList_show = true
                this.onSearch(this.value);
            },
            onLoad() {
                if (this.finished) return
                this.page++;
                this.onSearch(this.value);
            },
            onSearch(item) {
                if (this.value.length == 0) {
                    this.page = 1
                    this.finished = false
                    this.moreList_show = true
                    this.indexList = []
                }
                this.value = item
                if (item == '') return
                this.searchList.search = this.searchList.search.concat(item)
                this.$axios.post('/api/search/index', {
                    keyword: this.value,
                    page: this.page,
                    page_size: this.page_size,
                })
                    .then(res => {
                        var res = res.data.data
                        if (res.length < this.page_size) {
                            this.finished = true
                            this.finished_text = '没有更多了'
                        } else {
                            this.finished = false
                        }
                        if (this.page == 1) {
                            this.finished_text = ''
                        }
                        this.refreshing = false
                        this.loading = false;
                        this.listList = res
                        if (this.moreList_show) {
                            this.indexList = res
                            this.moreList_show = false
                        } else {
                            this.indexList = this.indexList.concat(res)
                        }
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },
            base_infoClick() {
                this.$axios.get('/api/search/base_info')
                    .then(res => {
                        var res = res.data.data
                        this.searchList = res
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },
            searchClick(e) {
                this.value = e
                this.onSearch(this.value)
            },
            clearClick() {
                if (this.searchList.search.length == 0) {
                    this.$toast('暂时没有要清空的记录哦！')
                    return
                }
                this.$dialog.alert({
                    title: '清空',
                    message: '确定要清空嘛？',
                    confirmButtonText: '确定',
                    confirmButtonColor: "#D53D3C",
                    showCancelButton: 'true',
                })
                    .then(() => {
                        this.$axios.get('/api/search/clear')
                            .then(res => {
                                this.base_infoClick()
                            })
                            .catch(err => {
                                this.$toast(err.response.data.message);
                            })
                    })

            },
            detailsClick(e) {
                this.$router.push({
                    path: '/details?id=' + e.anid
                })
            },
        }
    }
</script>

<style scoped>
    .search_input {
        position: sticky;
        top: 0px;
    }

    .record-right {
        height: 34px;
        border: 1px solid rgba(237, 239, 242, 1);
        border-radius: 4px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(191, 194, 204, 1);
        line-height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
    }

    .time {
        color: #909399;
        font-size: 22px;
        width: 330px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .time_acitve {
        width: 545px !important;
    }

    .list-right {
        margin-left: 30px;
        width: 100%;
    }

    .author_icon {
        width: 23px;
        height: 27px;
        margin-right: 10px;
    }

    .title-right {
        font-size: 24px;
        color: #FCAD0F;
    }

    .subtitle {
        font-size: 24px;
        color: #909399;
        display: flex;
        align-items: center;
        margin: 22px 0;
    }

    .subtitle > span {
        width: 230px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .title-right > span {
        font-weight: bold;
    }

    .title {
        color: #303133;
        font-size: 26px;
        width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
    }


    .record-left {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
    }

    .search-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .book_img {
        width: 100px;
        height: 140px;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        border-radius: 10px;
        display: inline-block;
    }


    .history {
        display: flex;
        flex-wrap: wrap;
    }

    .history > p {
        background: #F2F5F8;
        border-radius: 30px;
        padding: 0 30px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 26px;
        color: #606266;
        margin-right: 34px;
        margin-bottom: 30px;
    }

    .del_icon {
        font-size: 24px;
        color: #909399;
        display: flex;
        align-items: center;
    }

    .del_icon > img {
        width: 24px;
        height: 27px;
        margin-left: 10px;
    }

    .search-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 40px;
        margin-top: 15px;
    }

    .search-center {
        margin-top: 30px;
    }

    .search-title {
        font-size: 32px;
        color: #0A1C33;
    }

    .quxiao {
        color: #606266;
        font-size: 28px;
    }
</style>